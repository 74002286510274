.form-check-input {
  border: 1px solid rgba(33,50,91,.4)
}

.form-check-input:checked {
  background-color: #00c9a7;
}

.reminder-action {
  color: #000;
}

.reminder-action:hover {
  cursor: pointer;
  color: #1366ff;
}

.reminderPastDue {
  color: #ff0000;
  font-weight: bold;
}

.reminderDueToday {
  color: #ffae00;
  font-weight: bold;
}

.reminderNormal{
  color: #000;
}

.reminderCompleted{
  color: #00c9a7;
  text-decoration: line-through;
}

.xsmall{
  font-size: .65em;
}

.card{
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
}