.form-check-input {
  border: 1px solid rgba(33,50,91,.4)
}

.form-check-input:checked {
  background-color: #00c9a7;
}


.card-loss{
  background-color: #ffc107 !important;
}

.card-win{
  background-color: #00c9a7 !important;
}